// import parseTime, formatTime and set to filter
export { parseTime, formatTime, getAvg } from '@/utils'

// 格式化视频时长
export function formatDuration(num) {
  if (!num) return ''
  num = Math.floor(num / 1000)
  const hour = parseInt(num / 3600)
  const minute = parseInt((num % 3600) / 60)
  const second = num % 60
  return `${hour > 0 ? pad(hour) + ':' : ''}${pad(minute)}:${pad(second)}`
}

// 数字格式化
export function formatNumber(num) {
  if (num < 10000) return num || 0
  return parseFloat((num / 10000).toFixed(1)) + 'W'
  if (num < 10000) {
    return parseFloat((num / 1000).toFixed(2)) + 'K'
  }
  if (num < 10000000) {
    return parseFloat((num / 10000).toFixed(2)) + 'W'
  }
  return parseFloat((num / 10000000).toFixed(2)) + 'KW'
}

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function pad(num) {
  return num > 9 ? num : '0' + num
}


export function numberFormat(num) {
  return (num > 0 ? '+' : '') + num
}

export function fmoney(s, n) {
  s /= 100
  /*
   * 参数说明：
   * s：要格式化的数字
   * n：保留几位小数
   * */
  n = n > 0 && n <= 20 ? n : 2;
  s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
  var l = s.split(".")[0].split("").reverse(),
    r = s.split(".")[1];
  var t = "";
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
  }
  return t.split("").reverse().join("") + "." + r;
}

export function getCoverAddr(coverAddr = []) {
  let src = (coverAddr || []).find(addr => /p\d+-dy\.byteimg\.com/.test(addr))
  if (src) return src
  src = (coverAddr || []).find(addr => /p[13]-dy-ipv6\.byteimg\.com/.test(addr)).replace(/-ipv6/, '')
  if (src) return src
  return coverAddr[0]
}

export function formatMessage(message = []) {
  let count = 0
  message.forEach(v => {
    count += v.count || 0
  })
  return count
}

export function formatStatus(row) {
}
