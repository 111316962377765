<template>
  <div class="print-container order-container">
    <div class="order-item" v-for="order in print.orders">
      <template v-if="order.req_user">
        <p class="order-title">{{order.req_user}}</p>
        <br/>
      </template>
      <p>收件人/Receiver:</p>
      <p>{{order.conn_user}}</p>
      <p>{{order.conn_phone}}</p>
      <p>{{order.to_address}}</p>
      <p v-if="order.postal_code || order.city">{{order.postal_code}} {{order.city}}</p>
      <br/>
      <div class="small">
        <p>发件人/Sender:</p>
        <p>{{order.dipm_abbr}} c/o Murop AB</p>
        <p>Pionjärvägen 23</p>
        <p>19560 Arlandastad</p>
      </div>
      <div class="item-qrcode" v-if="order.qrcode">
        <img :src="order.qrcode" alt="" />
      </div>
      <p class="small">参考号：{{order.order_no}}</p>
      <p v-if="order.remark" class="small">备注/Remarks：{{order.remark}}</p>
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapState } from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(['print'])
  },
}
</script>

<style lang="less" scoped>
.order-container {
  display: none;
}
.order-title {
  font-size: 26px;
  font-weight: 600;
  text-align: center;
}
.order-item {
  page-break-after: always;
  line-height: 1.2;
  font-size: 24px;
  word-wrap: break-word;
  word-break: break-all;
  p {
    margin: 0;
  }
  .item-qrcode {
    margin: 25px 0;
    text-align: center;
    img {
      width: 100px;
    }
  }
  .small {
    font-size: 20px;
  }
}
</style>
