import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '../views/layout/Layout'
import AppMain from '../views/layout/components/AppMain'

/**
 * 实例化vue的时候只挂载constantRouter
 * 如果没有项目中没有权限的概念，应该把所有的路由配置constantRouter中
 */

export const constantRouterMap = [
  {path: '/login', component: () => import('@/views/login/login'), hidden: true},
  //{ path: '/forget', component: () => import('@/views/login/forget_pwd'), hidden: true },
  {path: '/404', component: () => import('@/views/404'), hidden: true},
  {
    path: '/',
    component: Layout,
    redirect: 'dashboard',
    children: [
      {
        path: 'dashboard',
        name: '首页',
        component: () => import('@/views/dashboard/index'),
        meta: {title: '首页', icon: 'home'},
      },
      {
        path: '403',
        name: '403',
        component: () => import('@/views/403'),
        hidden: true
      }
    ]
  },
]

/**
 * 异步挂载的路由
 * 根据权限动态加载的路由表
 */
export const asyncRouterMap = [
  {
    path: '/apply-order',
    component: Layout,
    meta: { permissions: ['admin', 'diplomatic'] },
    name: '申请单管理',
    children: [
      {
        path: 'list',
        meta: {title: '申请单', icon: 'apply_order', permissions: ['admin', 'diplomatic']},
        component: () => import('@/views/applyOrder/list'),
      },
    ]
  },
  {
    path: '/video',
    component: Layout,
    meta: { permissions: ['admin', 'diplomatic'], icon: 'video', title: '视频预约' },
    name: '视频预约',
    children: [
      {
        path: 'list',
        meta: {title: '预约列表', permissions: ['admin', 'diplomatic']},
        component: () => import('@/views/video/list'),
      },
      {
        path: 'config',
        meta: {title: '预约设置', permissions: ['admin', 'diplomatic']},
        component: () => import('@/views/video/config'),
      },
      {
        path: 'chat',
        meta: {title: '视频会议', permissions: ['admin', 'diplomatic']},
        component: () => import('@/views/video/chat'),
        hidden: true
      },
    ]
  },
  {
    path: '/user',
    component: Layout,
    meta: { permissions: ['admin'] },
    name: '账户管理',
    children: [
      {
        path: 'list',
        meta: {title: '账户管理', icon: 'team', permissions: ['admin']},
        component: () => import('@/views/user/list'),
      },
    ]
  },
  {
    path: '/dipm',
    component: Layout,
    meta: { permissions: ['admin'] },
    name: '使馆管理',
    children: [
      {
        path: 'list',
        meta: {title: '使馆管理', icon: 'dipm', permissions: ['admin']},
        component: () => import('@/views/diplomatic/list'),
      },
    ]
  },
  {
    path: '/apply-type',
    component: Layout,
    meta: { permissions: ['admin', 'diplomatic'] },
    name: '使馆费类别',
    children: [
      {
        path: 'list',
        meta: {title: '使馆项目', icon: 'goods', permissions: ['admin', 'diplomatic']},
        component: () => import('@/views/applyOrder/costType'),
      },
    ]
  },
  {
    path: '/dipm-log',
    component: Layout,
    meta: {title: '费用结算', icon: 'money', permissions: ['admin']},
    name: '使馆费结算',
    children: [
      {
        path: 'delivery',
        meta: {title: '派送费结算', permissions: ['admin']},
        component: () => import('@/views/checkout/deliveryFee'),
      },
      {
        path: 'delivery-records',
        meta: {title: '派送费记录', permissions: ['admin']},
        component: () => import('@/views/checkout/deliveryRecords'),
      },
      {
        path: 'list',
        meta: {title: '使馆费结算', permissions: ['admin']},
        component: () => import('@/views/checkout/dipmFee'),
      },
      {
        path: 'records',
        meta: {title: '使馆费记录', permissions: ['admin']},
        component: () => import('@/views/checkout/records'),
      },
    ]
  },
  {
    path: '/dipm-log',
    component: Layout,
    meta: {permissions: ['diplomatic']},
    name: '使馆费结算',
    children: [
      {
        path: 'records',
        meta: {title: '结算记录', icon: 'money', permissions: ['diplomatic']},
        component: () => import('@/views/checkout/records'),
      },
    ]
  },
  {
    path: '/operate',
    component: Layout,
    meta: { permissions: ['admin'] },
    name: '运维管理',
    children: [
      {
        path: 'email',
        meta: { title: '邮件发送', icon: 'share', permissions: ['admin'] },
        component: () => import('@/views/operate/email')
      }
    ]
  },
  {
    path: '/customer',
    component: Layout,
    meta: { permissions: ['admin'] },
    name: '客户管理',
    children: [
      {
        path: 'list',
        meta: {title: '客户管理', icon: 'team', permissions: ['admin']},
        component: () => import('@/views/customer/list'),
      },
    ]
  },
]

export default new Router({
    mode: 'history', //后端支持可开
    scrollBehavior: () => ({y: 0}),
    routes: constantRouterMap
})
