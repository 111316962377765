import QRCode from 'qrcode'

export default {
  state: {
    orders: [],
  },

  mutations: {
    SET_ORDERS(state, orders) {
      state.orders = orders
    }
  },

  actions: {
    async formatOrders({ commit }, orders) {
      for (let order of orders) {
        let url = await QRCode.toDataURL(order.order_id, { width: 400, margin: 0 })
        order.qrcode = url
      }
      commit('SET_ORDERS', orders)
    }
  }
}
