<template>
  <div>
    <svg
      :class="{'is-active':isActive}"
      t="1492500959545"
      class="hamburger"
      style=""
      viewBox="0 0 1142 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="6732"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="72"
      height="64"
      @click="toggleClick">
      <path
        d="M15.862639 31.725279m59.516624 0l991.351515 0q59.516623 0 59.516623 59.516623l0-0.06345q0 59.516623-59.516623 59.516623l-991.351515 0q-59.516623 0-59.516624-59.516623l0 0.06345q0-59.516623 59.516624-59.516623Z"
        p-id="6733" />
      <path
        d="M15.862639 448.15129m59.516624 0l594.785529 0q59.516623 0 59.516623 59.516623l0-0.06345q0 59.516623-59.516623 59.516623l-594.785529 0q-59.516623 0-59.516624-59.516623l0 0.06345q0-59.516623 59.516624-59.516623Z"
        p-id="6734" />
      <path
        d="M904.804955 313.763008l204.628049 178.930574a19.796574 19.796574 0 0 1 0 29.821762l-204.6915 178.359518A9.898287 9.898287 0 0 1 888.30781 693.514597V321.250174a9.898287 9.898287 0 0 1 16.497145-7.487166z"
        p-id="6735" />
      <path
        d="M15.862639 864.51385m59.516624 0l991.351515 0q59.516623 0 59.516623 59.516624l0-0.063451q0 59.516623-59.516623 59.516623l-991.351515 0q-59.516623 0-59.516624-59.516623l0 0.063451q0-59.516623 59.516624-59.516624Z"
        p-id="6736" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Hamburger',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    toggleClick: {
      type: Function,
      default: null
    }
  }
}
</script>

<style scoped>
.hamburger {
	display: inline-block;
	cursor: pointer;
	width: 20px;
	height: 20px;
	transform: rotate(0deg);
	transition: .38s;
	transform-origin: 50% 50%;
}
.hamburger.is-active {
	transform: rotate(180deg);
}
</style>
