<template>
  <el-dialog
    :visible="visible"
    :width="width"
    :title="title"
    destroy-on-close
    :key="dialogIndex"
    @close="close"
  >
    <slot />
    <div slot="footer" class="dialog-footer">
      <div class="flex-1">
        <slot name="footer-left" />
      </div>
      <el-button size="small" @click="close" v-if="cancelButtonText">{{cancelButtonText}}</el-button>
      <el-button size="small" type="primary" @click="submit" :loading="loading" :disabled="disabled" v-if="confirmButtonText">{{confirmButtonText}}</el-button>
    </div>
  </el-dialog>
</template>

<script type="text/javascript">
let dialogIndex = 0
export default {
  props: {
    title: String,
    width: {
      type: [Number, String],
      default: '50%'
    },
    visible: Boolean,
    disabled: Boolean,
    confirmButtonText: {
      type: String,
      default: '确 定'
    },
    cancelButtonText: {
      type: String,
      default: '取 消'
    },
    onSubmit: {
      type: Function,
      default: function () {}
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    dialogIndex() {
      return dialogIndex
    }
  },
  created() {
    dialogIndex += 1
  },
  methods: {
    submit() {
      let xhr = this.onSubmit()
      if (xhr && xhr instanceof Promise) {
        this.loading = true
        xhr.then(() => {
          this.loading = false
          this.$emit('update:visible', false)
        })
        .catch(() => {
          this.loading = false
        })
      }
    },
    close() {
      this.$emit('update:visible', false)
    }
  }
}
</script>
