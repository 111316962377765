import moment from 'dayjs'

export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  //const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  const format = cFormat || '{y}-{m}-{d}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    if (typeof time === 'string' && /-/.test(time)) {
      //time = time.replace(/-/g, '/')
      if (cFormat === '{y}-{m}-{d}') {
        return moment(time).format('YYYY-MM-DD')
      }
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'shallowClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}


export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

export function trimParams(params = {}) {
  let obj = {}
  for (let o in params) {
    if (params.hasOwnProperty(o) && params[o] !== '') {
      if (params[o] === null) continue;
      if (typeof params[o] === 'object' && params[o].length) {
        obj[o] = params[o].join(',')
        continue
      }
      obj[o] = params[o]
    }
  }
  return obj
}

export function numberToCN(num) {
  const map = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  num = +num;
  if (num < 10) {
    return map[num];
  } else if (num < 100) {
    let shi = Math.floor(num / 10);
    num -= shi * 10;
    return (shi > 0 ? map[shi] : "") + "十" + (num > 0 ? map[num] : "");
  } else if (num < 1000) {
    let bai = Math.floor(num / 100);
    num -= bai * 100;
    let shi = Math.floor(num / 10);
    num -= shi * 10;
    return `${map[bai]}百${shi > 0 ? map[shi] + "十" : "零"}${map[num]}`;
  }
  return num;
}

// 验证字符长度
export function validateRange(rule, value = '', callback) {
  var len = 0;  
  for (var i=0; i < value.length; i++) {
    var c = value.charCodeAt(i);  
    //单字节加1
    if ((c >= 0x0001 && c <= 0x007e) || (0xff60<=c && c<=0xff9f)) {
      len++;
    } else {
      len += 2;
    }   
  }

  const min = rule.min !== undefined ? rule.min : 0
  const max = rule.max !== undefined ? rule.max : Infinity
  if (!len || len < min || len > max) {
    callback(new Error(rule.message))
    return
  }
  callback()
}

export function filterNumber(e) {
  const key = e.key
  if (key === 'e' || key === '.' || key === '-') {
    e.returnValue = false
    return false
  }
  return true
}

export function getDate(time) {
  return new Date(time.replace(/-/g, '/'))
}

export function getAvg(a, b) {
  if (a > 0 && b > 0) {
    return Math.round(a / b)
  }
  return 0
}

export function getChildren(id, list) {
  const children = list.filter(v => v.parent_id === id)
  if (children.length > 0) {
    return children.map(v => {
      const children = getChildren(v.id, list)
      if (children.length > 0) {
        v.children = children
      }
      return v
    })
  }
  return []
}


export function download(content, fileName) {
  const blob = new Blob([content])
  const a = document.createElement('a')
  const url = window.URL.createObjectURL(blob)
  const filename = fileName
  a.href = url
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(url)
}

export function tplRender(tpl, context = {}) {
  return tpl.replace(/\{\{([^\}]+)\}\}/g, (match, key) => (context[key]));
}

export function getParams(str) {
  if (!str) return null
  const query = str.split('&')
  let params = {}
  for (let item of query) {
    const tmp = item.split(/\s*=\s*/g)
    params[tmp[0]] = tmp[1]
  }

  return Object.keys(params).length > 0 ? params : null
}
