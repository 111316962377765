import axios from 'axios'
import { Message } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'
import { trimParams } from '@/utils/index'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 50000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  }
})

// request拦截器
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      const token = getToken()
      if (token) {
        config.headers['Authentication'] = `Basic ${token}`
      }
    }
    return config
  },
  error => {
    console.log(error) //debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    if ((response.headers['content-type'] || '').indexOf('application/json') === -1) {
      return response.data
    }
    // common接口
    if (response.data.code === 200) {
      return response.data
    }
    if (response.data.status !== 200) {
      if (response.data.status === 302) {
        Message.error('登录已过期，请重新登录~')
        setTimeout(() => {
          window.location = '/login'
        }, 1000)
      }
      else {
        if (response.data.status === 500) {
          (new Image).src = `https://log.dingmatrix.com?url=${encodeURIComponent(response.config.url)}&_t=${+ new Date()}&error=${encodeURIComponent((response.data.message || '').substr(0, 100))}`
        }
        Message.error(response.data.message || '内部错误')
      }
      return Promise.reject(response.data.message)
    }
    return response.data.data;
  },
  error => {
    // console.log('响应错误->', error, error.response) // for debug
    if(error.response.status === 403){
      Message.error('权限不足')
    }
    return Promise.reject(error)
  }
)

export function get(url, params = {}, options = {}) {
  return service.get(url, { params: trimParams(params), ...options })
}

export function post(url, data = {}, options = {}) {
  return service.post(url, data, options)
}

export function patch(url, data = {}) {
  return service.patch(url, data)
}
export function del(url, data = {}) {
  return service.delete(url, data)
}
export function put(url, data = {}) {
  return service.put(url, data)
}
export function upload(url, formData) {
  return service.post(url, formData, {
    'Content-Type': 'multipart/form-data'
  })
}

export default service
