import { get, post, put, del } from '@/utils/request'

export default {
  name: 'diplomatic',
  getList(params) {
    return get(`/rest/${this.name}`, params)
  },
  add(data) {
    return post(`/rest/${this.name}`, data)
  },
  update(uuid, data) {
    return put(`/rest/${this.name}/${uuid}`, data)
  },
  del(uuid) {
    return del(`/rest/${this.name}?id=${uuid}`)
  },
  task() {
    return get(`/rest/${this.name}/commission`)
  },
}
