<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <!-- or name="fade" -->
      <!-- <router-view :key="key"></router-view> -->
      <router-view/>

    </transition>
    
      <!-- 模态框 -->
    <el-dialog width="40%" :visible.sync="dialogVisible" :title="'修改密码'" destroy-on-close :key="dialogIndex" @closed="closed">
      <el-form :model="userForm" label-width="80px" label-position="left" :rules="rules" ref="userForm">
        <el-form-item label="原密码" prop="oldPassword">
          <el-input type="password" placeholder="请输入密码" v-model="userForm.oldPassword"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input type="password" placeholder="请输入密码" v-model="userForm.newPassword"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="newPassword2">
          <el-input type="password" placeholder="请输入密码" v-model="userForm.newPassword2"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button @click="dialogVisible=false">取消</el-button>      
        <el-button type="primary" @click=updatePassword>确定</el-button>
      </div>
  </el-dialog>  
    
  </section>
</template>

<script>

import { login, updatePassword } from '@/api/login'
import store from '@/store'

export default {
  name: 'AppMain',
  computed: {
    // key() {
    //   return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
    // }
  },
  data(){
    const validateNewPassword = (rule, value, callback) => {
      let message = ''
      if (!value || !/^[0-9a-zA-Z]{6,}$/.test(value)) {
        message = '必填，且为不小于6位的数字字母或组合'
      }
      else if (value === this.userForm.oldPassword) {
        message = '新密码与旧密码不能相同'
      }
      if (message) {
        callback(new Error(message))
        return
      }
      callback()
    }

    const validateNewPassword2 = (rule, value, callback) => {
      if (!value || value !== this.userForm.newPassword) {
        callback(new Error('两次输入密码不一致'))
        return
      }
      callback()
    }

    return {
      dialogVisible: false,
      id: 3,
      userForm: {
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
      },
      rules: {
        oldPassword: [
          { required: true, message: '不能为空' },
        ],
        newPassword: [
          { validator: validateNewPassword }
        ],
        newPassword2: [
          { validator: validateNewPassword2 }
        ]
      },
      dialogIndex: 0,
    }
  },
  mounted(){
    // 订阅
    PubSub.subscribe('passwordForm',(event,data)=>{
        this.dialogVisible=data.dialogVisible;
    }),
    PubSub.subscribe(this.id,(event,data)=>{
        this.id=data.id;
    })
  },
  methods:{
    closed() {
      this.dialogIndex++
      this.userForm = {}
    },
   updatePassword(){
     this.$refs.userForm.validate(valid => {
       if (!valid) return
        //调用ajax发送的方法得到返回结果
        let $this = this
        updatePassword(this.userForm.oldPassword, this.userForm.newPassword).then(response=>{
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          store.dispatch('FedLogOut').then(() => {
            $this.$router.push({ path: '/login' });
          })
          this.dialogVisible = false
        })
     })
    }
  }


}
</script>

<style scoped>
.app-main {
  /*50 = navbar  */
  height: calc(100vh - 50px);
  position: relative;
  overflow: hidden;
  padding: 24px;
  overflow-y: auto;
}
</style>
