<template>
  <el-tag :type="type">{{name}}</el-tag>
</template>

<script type="text/javascript">
export default {
  props: {
    order: Object
  },
  computed: {
    type() {
      const { status, review_status } = this.order
      if (status === 99 || (status === 10 && review_status === 3)) return 'danger'
      if (status === 70) return 'success'
      return 'info'
    },
    name() {
      const { status } = this.order
      // 审核中
      if (status === 10) {
        return this.order.review_status_name
      }
      // 揽件 || 派送中
      if (status === 30 || status === 60) {
        return this.order.logistics_status_name
      }
      // 其他状态
      return this.order.status_name
    },
  }
}
</script>
