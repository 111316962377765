import { login, getInfo, getPermissions, loginByCode, logout } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { constantRouterMap, asyncRouterMap } from '@/router'

/**
 * 根据路由所需角色和当前用户所拥有的角色对比，来判断该用户是否可以看到该路由对应的菜单
 * @param permissions
 * @param route
 */
function hasPermission(permissions, route) {
  if (route.path === '*') return true
  if(permissions.indexOf('_admin') >= 0){  // 用户系统管理权限的用户默认拥有所有权限
    return true
  } else if (route.meta && route.meta.permissions) {
    return permissions.some(role => route.meta.permissions.indexOf(role) >= 0)
  } 
}

/**
 * 递归过滤动态路由
 * @param routes asyncRouterMap
 * @param permissions
 */
export function filterAsyncRouterMap(routes, permissions) {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route, meta: { ...(route.meta || {}), ...(permissions.indexOf('admin') > -1 ? route.adminMeta || {} : {}) } }
    if (hasPermission(permissions, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRouterMap(tmp.children, permissions)
      }
      res.push(tmp)
    }
    else if (permissions.indexOf('vip0') >= 0 && tmp.meta.permissions.indexOf('vip1') !== -1) {
      if (tmp.children) {
        tmp.children = filterAsyncRouterMap(tmp.children, permissions)
      }
      res.push(
        tmp.component.name !== 'Layout'
        ? { ...tmp, component: () => import('@/views/403') }
        : tmp
      )
    }
  })
  return res
}

const user = {
  state: {
    token: getToken(),
    name: '',
    user_id: '',
    avatar: '',
    diplomaticDTO: {},
    permissions: [],
    routes: constantRouterMap,
    addRoutes:[]
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_USER_ID: (state, user_id) => {
      state.user_id = user_id
    },
    SET_DIPLOMATIC_DTO: (state, dto) => {
      state.diplomaticDTO = dto
    },
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes;
      state.routes = constantRouterMap.concat(routes);
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        login(username, userInfo.password, userInfo.type).then(res => {
          setToken(res.token)
          commit('SET_TOKEN', res.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    LoginByCode({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByCode(userInfo.mobile, userInfo.verifyCode, userInfo.type).then(response => {
          setToken(response.token)
          commit('SET_TOKEN', response.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token).then(response => {
          let data = response
          commit('SET_PERMISSIONS', [data.role])
          commit('SET_NAME', data.nickname)
          commit('SET_USER_ID', data.uuid)
          commit('SET_AVATAR', data.avatar)
          commit('SET_DIPLOMATIC_DTO', data.diplomaticDTO || {})

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 生成可访问的路由表
    GenerateRoutes({ commit, state }, permissions){
      return new Promise(resolve => {
        let accessedRoutes = filterAsyncRouterMap(asyncRouterMap, permissions)
        commit('SET_ROUTES', accessedRoutes)
        resolve(accessedRoutes)
      })
    },

    // 前端 登出
    FedLogOut({ commit, state }) {
          commit('SET_TOKEN', '')
          commit('SET_PERMISSIONS', [])
          removeToken()
    },

    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_PERMISSIONS', [])

          removeToken()
          resolve()
        })
        .catch(reject)
      })
    },
  }
}

export default user
