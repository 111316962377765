import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI, { Message } from 'element-ui'
import '@/styles/reset.less'
//import 'element-ui/lib/theme-chalk/index.css'
import './element-variables.scss'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n

import '@/styles/index.less' // global css

import App from './App'
import router from './router'
import store from './store'
import { parseTime } from './utils/index'
import * as filters from '@/filters/'
import OrderStatus from '@/components/OrderStatus'
import CDialog from '@/components/Dialog'

import '@/icons' // icon
import '@/permission' // permission control

Vue.component('OrderStatus', OrderStatus)
Vue.component('c-dialog', CDialog)
Vue.prototype.$parseTime = parseTime
Vue.use(ElementUI)
Vue.prototype.$message = Message

// register global utility filters
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})


Vue.config.productionTip = false
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
