<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import dipm from '@/api/dipm'
import { mapState } from 'vuex'
import dayjs from 'dayjs'
export default {
  name: 'App',
  computed: {
    ...mapState(['user'])
  },
  watch: {
    'user.permissions': function () {
      this.getTask()
    }
  },
  methods: {
    getTask() {
      if (this.user.permissions.indexOf("diplomatic") === -1) return;
      dipm.task()
      .then(res => {
        for (let item of res.room || []) {
          this.$notify({
            title: `${item.customer_name}的视频预约`,
            type: 'warning',
            message: `预约时间段：${dayjs(item.start).format('MM-DD HH:mm')} ~ ${dayjs(item.end).format('HH:mm')}`,
            duration: 0,
          })
        }
      })
    }
  }
}
</script>
