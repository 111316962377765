import request from '@/utils/request'

export function login(user_name, password, type) {
  return request({
    url: '/rest/user/login',
    method: 'post',
    data: {
      user_name,
      password,
    }
  })
}

export function sendSms(mobile) {
  return request({
    url: '/rest/user/send-sms',
    method: 'post',
    data: { mobile }
  })
}

export function loginByCode(mobile, code, type) {
  return request({
    url: '/rest/user/login-by-mobile',
    method: 'post',
    data: {
      mobile,
      code,
      type,
    }
  })
}

export function updatePassword(old_password, new_password) {
  return request({
    url: `/rest/user/password`,
    method: 'put',
    data: { old_password, new_password }
  })
}

export function getInfo(token) {
  return request({
    url: '/rest/user/info',
    method: 'get',
  })
}

export function getPermissions() {
  return request({
    url: '/rest/user/permissions',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/rest/user/logout',
    method: 'post'
  })
}
