<template>
  <el-scrollbar wrap-class="scrollbar-wrapper">
    <el-menu
      :show-timeout="200"
      :default-active="$route.path"
      :collapse="isCollapse"
      mode="vertical"
      background-color="#001529"
      text-color="#bfcbd9"
      active-text-color="#ffffff"
    >
      <div class="sidebar-logo" :class="{collapse: isCollapse}">
        <img :src="isCollapse ? '/static/logo_db_small.png' : '/static/logo_db.png'" />
      </div>
      <sidebar-item v-for="route in my_routes" :key="route.path" :item="route" :base-path="route.path"/>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'


export default {
  components: { SidebarItem },
  computed: {
    ...mapGetters([
      'sidebar',
      'my_routes',
      'name'
    ]),
    routes() {
      return this.$router.options.routes
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  }
}
</script>

<style scoped>
.sidebar-logo {
  height: 60px;
  padding-top: 5px;
  background-color: #002140;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-logo img {
  height: 42px;
}
.sidebar-logo.collapse img {
  height: 32px;
}
</style>
